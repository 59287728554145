<template>
  <v-container fluid class="ma-0 pa-0" :class="{'light-background': $vuetify.breakpoint.xsOnly, 'white': $vuetify.breakpoint.smAndUp}">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto px-2">
        <v-layout row wrap v-if="$vuetify.breakpoint.xsOnly">
          <v-flex xs6 pl-4 pr-2>
            <v-btn 
              color="primary"
              depressed dark block
              :loading="g_isLoading"
              class="normalcase graphik-medium my-3 border-radius-5"
              @click="$goTo('/perfil/usuario', 'goto_profile_user')"
            >{{ $ml.get('header_title_profile') }}</v-btn>
          </v-flex>

          <v-flex xs6 pr-4 pl-2>
            <v-btn 
              color="primary"
              depressed dark block
              :loading="g_isLoading"
              class="normalcase graphik-medium my-3 border-radius-5"
              @click="$goTo('/perfil/polizas', 'goto_profile_policies')"
            >{{ $ml.get('header_title_policies') }}</v-btn>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12 sm8 class="pa-3">
            <router-view :key="$route.fullPath" />
          </v-flex>

          <v-flex xs12 sm4 class="pa-3">
            <v-card flat tile :color="$vuetify.breakpoint.smAndUp ? 'white' : 'light-background'">
              <v-img
                contain
                position="center bottom"
                :src="image"
                :lazy-src="image"
                class="mb--24"
              ></v-img>

              <v-card-text class="light-background">
                <v-layout row wrap>
                  <v-flex xs12 text-xs-center>
                    <template v-if="$vuetify.breakpoint.smAndUp">
                      <v-btn 
                        color="primary"
                        depressed dark block
                        :loading="g_isLoading"
                        class="normalcase graphik-medium my-3 border-radius-5"
                        @click="$goTo('/perfil/usuario', 'goto_profile_user')"
                      >{{ $ml.get('header_title_profile') }}</v-btn>

                      <v-btn 
                        color="primary"
                        depressed dark block
                        :loading="g_isLoading"
                        class="normalcase graphik-medium my-3 border-radius-5"
                        @click="$goTo('/perfil/polizas', 'goto_profile_policies')"
                      >{{ $ml.get('header_title_policies') }}</v-btn>
                    </template>

                    <v-btn 
                      color="pantene"
                      depressed dark
                      :loading="g_isLoading"
                      class="normalcase graphik-bold-italic border-radius-5"
                      @click="$authLogout"
                    >{{ $ml.get('header_title_logout') }}</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      image: require('@/assets/img/images/profile.png')
    }
  }
}
</script>

<style scoped>
  .mb--24 {
    margin-bottom: -24px !important;
  }
</style>
